<template>
  <div class="container">
    <div class="top flex jc-b">
      <div class="top-t">发起签署</div>
      <div>
        <el-button @click="show = false">返 回</el-button>
        <el-button type="warning" @click="submitSignFlow"
          >发 起 签 署</el-button
        >
      </div>
    </div>

    <div class="p-title">发起方式</div>
    <div class="grid2">
      <div class="part" v-if="!signTemplateId">
        <div class="s-t">上传合同文件发起签署</div>
        <el-steps :active="1" simple>
          <el-step title="上传文件" icon="el-icon-upload"></el-step>
          <el-step title="设置签署区" icon="el-icon-upload"></el-step>
          <el-step title="用户签署合同" icon="el-icon-edit"></el-step>
        </el-steps>
        <eFileUpload
          :list="fileList"
          ref="fu"
          @success="fileUploadSuccess"
        ></eFileUpload>
      </div>
      <div class="part" v-if="!upload.fileId">
        <div class="s-t">
          使用签署流程模板发起签署
          <el-tooltip
            content="签署流程模板是适用于合同中某些内容需要针对不同的人填写不同值的情况，比如：乙方姓名、身份证等。"
            placement="top"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <el-steps :active="1" simple>
          <el-step title="选择模板" icon="el-icon-upload"></el-step>
          <el-step title="发起流程" icon="el-icon-upload"></el-step>
          <el-step title="用户填写信息" icon="el-icon-edit"></el-step>
          <el-step title="用户签署合同" icon="el-icon-edit"></el-step>
        </el-steps>
        <el-button type="primary" size="small" @click="toChangeFlowTemp"
          >选择签署模板</el-button
        >
        <div class="temp-wrap">
          签署模板：{{ signTemplate.signTemplateName || "请选择签署模板" }}
        </div>
      </div>
    </div>

    <el-form>
      <el-form-item label="签署任务名称" required>
        <el-input v-model="form.title" placeholder="请输入内容"></el-input>
      </el-form-item>
      <div class="p-title">发起方</div>
      <el-form-item label="发起方"> 杭州汇合智能科技有限公司 </el-form-item>
      <el-form-item label="自动盖章" v-if="createType == 'file'">
        <el-switch
          v-model="autoSign"
          active-color="#13ce66"
          inactive-color="#eee"
          active-text="需要"
          inactive-text="不需要"
        >
        </el-switch>
        <div class="tips">
          选择后会自动在合同文件中盖'杭州汇合智能科技有限公司'的印章
        </div>
      </el-form-item>
      <el-form-item label="自动签章区域" v-if="autoSign">
        <el-link
          type="primary"
          href="https://open.esign.cn/tools/seal-position"
          target="_blank"
          >获取盖章位置</el-link
        >
        <el-input class="m-r10" placeholder="页码" v-model="positionInfo.page">
          <template slot="prepend">页码</template>
        </el-input>
        <el-input class="m-r10" placeholder="x坐标" v-model="positionInfo.x">
          <template slot="prepend">x坐标</template>
        </el-input>
        <el-input class="m-r10" placeholder="y坐标" v-model="positionInfo.y">
          <template slot="prepend">y坐标</template>
        </el-input>
      </el-form-item>
      <div class="p-title">签署方</div>
      <el-form-item label="签署方类型" required>
        <el-radio v-model="form.sign_role" label="coach">教练</el-radio>
        <el-radio v-model="form.sign_role" label="mechanism">机构</el-radio>
        <el-radio v-model="form.sign_role" label="cooperator">合作商</el-radio>
      </el-form-item>
      <el-form-item label="设置签署方">
        <el-select
          v-model="currentSign"
          filterable
          remote
          reserve-keyword
          placeholder="请输入手机号"
          :remote-method="queryListInfo"
          @change="signUserChange"
        >
          <el-option
            v-for="item in searchList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
            <span style="float: left">{{ item.sname }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.sphone
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="签署方" required>
        <el-dropdown trigger="click" @command="typeCommand">
          <span class="m-r10" style="color: #409eff">
            {{ form.sign_target == 2 ? "个人" : "企业"
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="2">个人</el-dropdown-item>
            <el-dropdown-item command="1">企业</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-input class="m-r10" placeholder="签署主体" v-model="form.sign_name">
          <template slot="prepend">签署主体</template>
        </el-input>
        <el-input class="m-r10" placeholder="签署人" v-model="form.contacts">
          <template slot="prepend">签署人</template>
        </el-input>
        <el-input
          class="m-r10"
          placeholder="电话"
          v-model="form.contacts_phone"
        >
          <template slot="prepend">电话</template>
        </el-input>
      </el-form-item>
      <div class="tips">
        签署方为企业，[签署主体]请填写对方的企业名称，请填写正确的企业名称
      </div>
      <div class="tips">
        签署方为个人，请填写正确的[签署人]和[电话]，签署人需使用填写的电话登录e签宝签约
      </div>
    </el-form>
  </div>
</template>

<script>
var timer = "";
import eFileUpload from "../../../components/upload/eFileUpload.vue";
export default {
  components: {
    eFileUpload,
  },
  data() {
    return {
      form: {
        title: "",
        sign_role: "coach",
        sign_target: "2",
        sign_name: "",
        contacts: "",
        contacts_phone: "",
      },
      positionInfo: {
        page: "",
        x: "",
        y: "",
      },
      searchList: [],
      currentSign: "",

      createType: "file",
      signTemplateId: "",
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),

      fileList: [],
      fileData: {},
      fileUrl: "",
      upload: {
        fileId: "",
        fileName: "",
        path: "",
      },

      loading: false,

      autoSign: false,
    };
  },

  mounted() {
    if (this.$route.query.signTemplateId) {
      this.signTemplateId = this.$route.query.signTemplateId;
      this.createType = "template";
    }
    if (this.$route.query.fileId) {
      this.upload.fileId = this.$route.query.fileId;
      this.createType = "file";
      this.$get("user/esign/getFileStatus", {
        id: this.upload.fileId,
      }).then((res) => {
        this.upload.fileName = res.data.data.fileName;
        this.fileList = [{ name: this.upload.fileName }];
      });
    }
  },
  computed: {
    signTemplate() {
      return this.$store.state.signTemplate;
    },
  },

  methods: {
    //发起签署，跳转e签宝创建页面
    submitSignFlow() {
      if (!this.form.title) {
        this.$message("请填写签约任务名称");
        return;
      }
      if (!this.form.sign_name || !this.form.contacts || !this.form.contacts_phone) {
        this.$message("请填写签约方信息");
        return;
      }

      let data = {
        type: this.createType,
        target_id: this.currentSign.id,
        handler_id: this.adminInfo.id,
        handler_name: this.adminInfo.name,
        file_id: this.upload.fileId || null,
        file_name: this.upload.fileName || null,
        ...this.form,
      };

      this.$post("/user/signFlowRecord/insert", data).then((res) => {
        this.createSignFlow(res.data.data);
      });
    },
    //创建签署流程
    async createSignFlow(record) {
      let himaOrg = {
        signerType: 1,
        orgSignerInfo: {
          orgId: "2c4ed4c5690c476d9ebfde1efa034ca1",
        },
        signFields: [
          {
            fileId: "",
            customBizNum: "",
            normalSignFieldConfig: {
              autoSign: true,
              signFieldStyle: 1,
              signFieldPosition: {
                positionPage: this.positionInfo.page,
                positionX: this.positionInfo.x,
                positionY: this.positionInfo.y,
              },
            },
          },
        ],
      };

      //模板流程发起
      if (this.createType == "template") {
        const details = await this.getTempLateDetails(this.signTemplateId);
        let participants = {};
        participants.participantId = details.participants[0].participantId;
        if (this.form.sign_target == 1) {
          participants.orgParticipant ={
            orgName : this.form.sign_name,
            transactor: {
              transactorPsnAccount: this.form.contacts_phone,
              transactorName: this.form.contacts,
            },
            }
        } else {
          participants.psnParticipant = {
            psnName: this.form.contacts,
            psnAccount: this.form.contacts_phone,
          };
        }

        let data = {
          signTemplateId: this.signTemplateId,
          signFlowTitle: this.form.title,
          participants: [participants],
        };
        this.$post("/user/esignTemp/createSignFlow", data).then((res) => {
          let param = {
            id: record,
            sign_flow_id: res.data.data.signFlowId,
            status: 1,
          };
          this.$post("/user/signFlowRecord/update", param).then(res=>{
            this.$routers.push({
                name:'signFlowList'
            })
          });
        });
      } else {
        //直接上传文件发起签署
        let launch = {
          customBizNum: record,
          signFlowTitle: this.form.title,
          redirectUrl: this.$href+"#/main/signFlowList",
        };
        if (!this.upload.fileId) {
            this.$message("请选择合同文件")
            return
         
        }else{
             launch.fileId = this.upload.fileId;
          launch.fileName = this.upload.fileName;
          himaOrg.signFields[0].fileId = this.upload.fileId;
          himaOrg.signFields[0].customBizNum = record;
        }

        let singer = {
          noticeConfig: {
            noticeTypes: "1",
          },
        };
        //企业
        if (this.form.sign_target == 1) {
          singer.signerType = 1;
          singer.orgSignerInfo = {
            orgName: this.form.sign_name,
            transactorInfo: {
              psnAccount: this.form.contacts_phone,
              psnInfo: {
                psnName: this.form.contacts,
              },
            },
          };
        } else {
          //个人
          singer.signerType = 0;
          singer.psnSignerInfo = {
            psnInfo: {
              psnName: this.form.contacts,
            },
            psnAccount: this.form.contacts_phone,
          };
        }
        if (this.autoSign) {
          launch.signers = [himaOrg, singer];
        } else {
          launch.signers = [singer];
        }

        this.$post("user/esign/getSignUIPage", launch).then((res) => {
          window.location.href = res.data.data.signFlowInitiateUrl;
        });
      }
    },

    getTempLateDetails(id) {
      return new Promise((resolve, reject) => {
        this.$get("/user/esignTemp/queryTemplateDetails", {
          signTemplateId: id,
        })
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //选择签约方
    signUserChange(v) {
      this.currentSign = v;
      if (this.form.sign_role == "coach") {
        this.form.sign_name = v.real_name;
        this.form.contacts = v.real_name;
        this.form.contacts_phone = v.phone;
      } else if (this.form.sign_role == "cooperator") {
        this.form.sign_name = v.title;
        this.form.contacts = v.name;
        this.form.contacts_phone = v.phone;
      } else if (this.form.sign_role == "mechanism") {
        this.form.sign_name = v.mechanism_name;
        this.form.contacts = v.contacts;
        this.form.contacts_phone = v.contact_telephone;
      }
    },
    typeCommand(v) {
      this.form.sign_target = v;
    },
    toChangeFlowTemp() {
      this.$router.push({ name: "signTemplateList" });
    },
    //选择的文件成功上传到七牛云
    fileUploadSuccess(v) {
      this.fileData = v.fileData;
      this.fileUrl = v.fileUrl;
      this.fileUploadToEsign();
    },
    //文件上传到e签宝
    fileUploadToEsign() {
      let data = {
        fileName: this.fileData.name,
        fileSize: this.fileData.size,
        filePath: this.fileUrl,
      };
      this.loading = true;
      this.$get("user/esign/getUploadUrl", data).then((res) => {
        let v = res.data.data;
        this.upload.fileId = v.fileId;
        this.upload.path = v.fileUploadUrl;
        this.upload.fileName = this.fileData.name;
        timer = setInterval(() => {
          this.$get("user/esign/getFileStatus", {
            id: this.upload.fileId,
          }).then((res) => {
            if (
              res.data.data.fileStatus == 2 ||
              res.data.data.fileStatus == 5
            ) {
              clearInterval(timer);
              this.loading = false;
            }
          });
        }, 1000);
      });
    },
    //根据电话搜索签署方
    queryListInfo(query) {
      if (this.form.sign_role == "coach") {
        this.$get("user/coach/queryListPage", { phone: query, status: 2 }).then(
          (res) => {
            res.data.data.forEach((item) => {
              item.sname = item.real_name;
              item.sphone = item.phone;
            });
            this.searchList = res.data.data;
          }
        );
      } else if (this.form.sign_role == "cooperator") {
        this.$get("user/cooperator/queryListPage", { phone: query }).then(
          (res) => {
            res.data.data.forEach((item) => {
              item.sname = item.title;
              item.sphone = item.phone;
            });
            this.searchList = res.data.data;
          }
        );
      } else if (this.form.sign_role == "mechanism") {
        this.$get("user/mastermechanism/queryListPage", {
          contact_telephone: query,
        }).then((res) => {
          res.data.data.forEach((item) => {
            item.sname = item.mechanism_name;
            item.sphone = item.contact_telephone;
          });
          this.searchList = res.data.data;
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  margin-bottom: 30px;
  padding-right: 50px;
  .top-t {
    font-size: 20px;
  }
}
.p-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  .s-t {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .part {
    background-color: #f7f7f7;
    padding: 10px 20px 0;
  }
}

.temp-wrap {
  background-color: rgb(250, 242, 225);
  padding: 14px;
  border-radius: 4px;
  font-size: 14px;
  margin: 10px 0 20px;
}
.tips {
  font-size: 12px;
  color: rgb(105, 104, 104);
}
</style>